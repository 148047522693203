:root {
  --text-disabled-color: #878787;
  --text-hover-color: #b4b4b4;
  --text-color-active: #000;
  --send-success-text-hover-color: #046545;
  --large-numbers-color: #00bf7f;
  --ui-border-color-dark: #006946;
  --gray-transparent-bg-3: rgba(101, 101, 99, 0.6);
  --list-background-color: #818080;
  --box-background-color: #008458;
  --light-background-color: #fafafa;
  --text-primary-color: #ffffff;
  --ui-yellow: #E29E4A;
  --ui-yellow-darker: #d57500;
  --sub-text-color: #d7d5d5;
  --input-placeholder-color: #008458;
  --active-color: #00e195;
}

.notificationButton {
  background-color: var(--light-background-color);
  color: var(--text-color-active);
  border: 0;
  border-bottom: 1px solid var(--send-success-text-hover-color);
  border-radius: 5px;
  float: left;
  margin-right: 20px;
  width: 300px;
  height: 20px;
  cursor: pointer;
  font-weight: normal;
}

.activeNotificationButton {
  background-color: var(--large-numbers-color);
  color: var(--text-primary-color);
  border: 0;
  border-bottom: 1px solid var(--send-success-text-hover-color);
  border-radius: 5px;
  float: left;
  margin-right: 20px;
  width: 300px;
  height: 20px;
  cursor: pointer;
  font-weight: normal;
  animation: activeNotificationButton 2s alternate infinite;
}

@keyframes activeNotificationButton {
  from {
    background-color: var(--large-numbers-color);
  }

  to {
    background-color: var(--ui-border-color-dark);
  }
}

.messagesHeaderNotification {
  z-index: 3;
  position: absolute;
  margin-top: 25px;
  width: 300px;
  background-color: var(--text-disabled-color);
  height: 100px;
  border-radius: 3px;
  padding: 0;
  color: var(--text-color-active)
}

.messagesHeaderNotificationLoading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messagesHeaderNotificationList {
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.unreadMessagesHeaderNotificationList {
  margin-bottom: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: var(--box-background-color);
  display: flex;
  align-items: center;
}

.activeMessagesHeaderNotification {
  margin-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: var(--send-success-text-hover-color);
  display: flex;
  align-items: center;
}

.messagesHeaderNotificationList:hover {
  cursor: zoom-in;
  background-color: var(--text-hover-color);
}

.unreadMessagesHeaderNotificationList:hover {
  cursor: zoom-in;
  background-color: var(--text-hover-color);
}

.messagesNotificationChatScreen {
  background-color: var(--list-background-color);
  width: 300px;
  height: 300px;
  margin-top: 5px;
}

.messagesHeaderNotificationCheckmark {
  display: flex;
  align-items: center;
  padding: 4px;
}

.messagesHeaderNotificationCheckmark>svg {
  width: 14px;
  height: 14px;
  color: var(--active-color);
}


.messagesHeaderNotificationRoomMessages {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}