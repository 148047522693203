.scrollArea {
  overflow: hidden;
  position: relative;
}

.scrollable {
  max-height: inherit;
  overflow: hidden;
  zoom: 1;
}

.scrollX {
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
}

.scrollY {
  overflow-y: scroll;
}

.fit {
  height: 100%;
  width: 100%;
  min-width: 200px;
}

.fit>.scrollable {
  bottom: 0;
  left: 0;
  max-height: none;
  position: absolute;
  right: 0;
  top: 0;
}