.channelRoom {
  padding: 5px 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.channelRoom>.channelScrollableArea {
  width: 100%;
  height: 100%;
}

.channelMessagesDiv {
  display: flex;
}

.channelMessages {
  word-break: break-word;
  margin-top: 0;
  color: var(--text-primary-color);
  max-width: 70%;
}

.messageSender {
  font-weight: bold;
  color: var(--text-primary-color);
}

.channelRoomFooterButtons {
  display: flex;
  justify-content: space-between;
}

button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
  padding: 0;
}

.sendButton {
  color: var(--send-success-text-color);
}

.sendButton:hover {
  color: var(--send-success-text-hover-color);
}

.sendButton:disabled {
  color: var(--text-disabled-color);
  cursor: progress;
}

.resolvedButton {
  color: var(--ui-yellow);
}

.resolvedButton:hover {
  color: var(--ui-yellow-darker);
}

.subMessageSentDate {
  font-size: 10px;
  color: var(--sub-text-color);
  font-weight: normal;
  margin: 0px 5px;
}

.messageEdit {
  float: right;
  cursor: pointer;
}

.messageEdit:hover {
  font-weight: bold;
}

.sendChatMessage {
  border-radius: 3px;
}

.textAreaAndSpeechRecognition {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sendChatMessageTextArea {
  width: 100%;
  background-color: var(--gray-transparent-bg-3);
  border: 0;
  border-radius: 3px;
  color: var(--text-primary-color);
  padding: 0px 3px;
}

.sendChatMessageTextArea:focus {
  outline: none;
}

.sendChatMessageTextArea::placeholder {
  color: var(--large-numbers-color);
}